import { CRUDMutations } from '@/utils/store-utilities'

const defaultItem = (item) => ({
  ...item
})

export const state = () => ({
  item: null,
  items: []
})

export const mutations = {
  ...CRUDMutations(defaultItem)
}

export const getters = {}

export const actions = {
  createCategory({ commit }, item) {
    return this.$axios
      .$post('admin/categories/create', { ...item })
      .then((response) => {
        commit('add', { ...response.data })
        return response.data
      })
  },
  removeCategory({ commit }, item) {
    return this.$axios
      .$delete(`admin/categories/${item.id}`)
      .then((response) => {
        if (response.data) {
          commit('remove', item)
        }
      })
  },
  updateCategory({ commit }, item) {
    return this.$axios
      .$put(`admin/categories/${item.id}`, { ...item })
      .then((response) => {
        commit('update', { ...response.data })
        return response.data
      })
  },
  fetchCategory({ commit }, id) {
    return this.$axios.$get(`admin/categories/${id}`).then((response) => {
      return response.data
    })
  },
  fetchCategories({ commit }) {
    return this.$axios.$get('admin/categories').then((response) => {
      commit('load', response.data)
      return response.data
    })
  }
}
