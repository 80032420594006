<template>
  <div class="w-full">
    <Input
      v-bind="$attrs"
      :key="error"
      :min="min"
      :step="step"
      :error="error"
      type="number"
      v-on="$listeners"
    >
      <template #right>
        <div v-if="selectedCurrency">
          <DcSelect
            :value="currency"
            search-key="code"
            label-key="code"
            value-key="code"
            src="admin/currencies"
            class="w-36"
            @input="$emit('currency', $event)"
          />
        </div>
        <div v-else class="text-gray-500 text-sm px-3">
          {{ priceUnit || unit(unitKey) }}
        </div>
      </template>
    </Input>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    priceUnit: {
      type: String,
      default: null
    },
    unitKey: {
      type: String,
      default: 'price'
    },
    step: {
      type: Number,
      default: 0.01
    },
    min: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: 'GBP'
    },
    selectedCurrency: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('settings', ['unit']),
    inputListeners() {
      return {
        ...this.$listeners,
        input(e) {
          this.$emit('input', e.target.value)
        }
      }
    }
  },
  methods: {
    setCurrency(val) {
      this.$emit('currency', val)
    }
  }
}
</script>
