import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5299bbd3 = () => interopDefault(import('../pages/addresses/index.vue' /* webpackChunkName: "pages/addresses/index" */))
const _1f3c8182 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _17c28978 = () => interopDefault(import('../pages/carts/index.vue' /* webpackChunkName: "pages/carts/index" */))
const _6bbeb161 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _e7f359b8 = () => interopDefault(import('../pages/collection-products/index.vue' /* webpackChunkName: "pages/collection-products/index" */))
const _5fff4486 = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _2dd65e04 = () => interopDefault(import('../pages/coupons/index.vue' /* webpackChunkName: "pages/coupons/index" */))
const _fcbfc6ae = () => interopDefault(import('../pages/custom-categories/index.vue' /* webpackChunkName: "pages/custom-categories/index" */))
const _866dd17c = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _b3de8e3a = () => interopDefault(import('../pages/dashboard2/index.vue' /* webpackChunkName: "pages/dashboard2/index" */))
const _26019783 = () => interopDefault(import('../pages/discounts/index.vue' /* webpackChunkName: "pages/discounts/index" */))
const _4c3b1c8f = () => interopDefault(import('../pages/locations/index.vue' /* webpackChunkName: "pages/locations/index" */))
const _500d2497 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _26470095 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _ba414726 = () => interopDefault(import('../pages/meta-keys/index.vue' /* webpackChunkName: "pages/meta-keys/index" */))
const _e2f0c0ea = () => interopDefault(import('../pages/newsletters/index.vue' /* webpackChunkName: "pages/newsletters/index" */))
const _3423ceaa = () => interopDefault(import('../pages/option-values/index.vue' /* webpackChunkName: "pages/option-values/index" */))
const _0bb1294f = () => interopDefault(import('../pages/options/index.vue' /* webpackChunkName: "pages/options/index" */))
const _6a5b682c = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _5d8daf16 = () => interopDefault(import('../pages/pages/index.vue' /* webpackChunkName: "pages/pages/index" */))
const _e85588bc = () => interopDefault(import('../pages/product-labels/index.vue' /* webpackChunkName: "pages/product-labels/index" */))
const _5f400c0c = () => interopDefault(import('../pages/product-types/index.vue' /* webpackChunkName: "pages/product-types/index" */))
const _72a8f5ae = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _dad213f0 = () => interopDefault(import('../pages/redirects/index.vue' /* webpackChunkName: "pages/redirects/index" */))
const _177cc2e8 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _36edbacc = () => interopDefault(import('../pages/shipping-profiles/index.vue' /* webpackChunkName: "pages/shipping-profiles/index" */))
const _a43e924e = () => interopDefault(import('../pages/slugs/index.vue' /* webpackChunkName: "pages/slugs/index" */))
const _71fa3077 = () => interopDefault(import('../pages/stores/index.vue' /* webpackChunkName: "pages/stores/index" */))
const _46f92e9e = () => interopDefault(import('../pages/tags/index.vue' /* webpackChunkName: "pages/tags/index" */))
const _668d2062 = () => interopDefault(import('../pages/tax-rates/index.vue' /* webpackChunkName: "pages/tax-rates/index" */))
const _bbebdae2 = () => interopDefault(import('../pages/themes/index.vue' /* webpackChunkName: "pages/themes/index" */))
const _09cc7c8e = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _295eda9c = () => interopDefault(import('../pages/vendors/index.vue' /* webpackChunkName: "pages/vendors/index" */))
const _53d0c702 = () => interopDefault(import('../pages/wishlists/index.vue' /* webpackChunkName: "pages/wishlists/index" */))
const _33818338 = () => interopDefault(import('../pages/zones/index.vue' /* webpackChunkName: "pages/zones/index" */))
const _2852094b = () => interopDefault(import('../pages/addresses/create.vue' /* webpackChunkName: "pages/addresses/create" */))
const _1ff00d08 = () => interopDefault(import('../pages/blogs/create.vue' /* webpackChunkName: "pages/blogs/create" */))
const _9126100c = () => interopDefault(import('../pages/carts/create.vue' /* webpackChunkName: "pages/carts/create" */))
const _33cbc57d = () => interopDefault(import('../pages/categories/create.vue' /* webpackChunkName: "pages/categories/create" */))
const _70c4d210 = () => interopDefault(import('../pages/collections/create.vue' /* webpackChunkName: "pages/collections/create" */))
const _9e1933c2 = () => interopDefault(import('../pages/components/apiselect.vue' /* webpackChunkName: "pages/components/apiselect" */))
const _f2eb772a = () => interopDefault(import('../pages/components/autocompleteinput.vue' /* webpackChunkName: "pages/components/autocompleteinput" */))
const _603a4c4a = () => interopDefault(import('../pages/components/breadcrumb.vue' /* webpackChunkName: "pages/components/breadcrumb" */))
const _53984644 = () => interopDefault(import('../pages/components/breadcrumblink.vue' /* webpackChunkName: "pages/components/breadcrumblink" */))
const _28c1f90e = () => interopDefault(import('../pages/components/button.vue' /* webpackChunkName: "pages/components/button" */))
const _23bf57ca = () => interopDefault(import('../pages/components/checkbox.vue' /* webpackChunkName: "pages/components/checkbox" */))
const _6556d4fa = () => interopDefault(import('../pages/components/datepicker.vue' /* webpackChunkName: "pages/components/datepicker" */))
const _361b8bd1 = () => interopDefault(import('../pages/components/imageeditor.vue' /* webpackChunkName: "pages/components/imageeditor" */))
const _b6de5112 = () => interopDefault(import('../pages/components/imageloader.vue' /* webpackChunkName: "pages/components/imageloader" */))
const _a2b11e5a = () => interopDefault(import('../pages/components/input.vue' /* webpackChunkName: "pages/components/input" */))
const _6b842b94 = () => interopDefault(import('../pages/components/modal.vue' /* webpackChunkName: "pages/components/modal" */))
const _1ee713e6 = () => interopDefault(import('../pages/components/panel.vue' /* webpackChunkName: "pages/components/panel" */))
const _012079ee = () => interopDefault(import('../pages/components/popover.vue' /* webpackChunkName: "pages/components/popover" */))
const _51202d30 = () => interopDefault(import('../pages/components/priceinput.vue' /* webpackChunkName: "pages/components/priceinput" */))
const _738c1c43 = () => interopDefault(import('../pages/components/select.vue' /* webpackChunkName: "pages/components/select" */))
const _5b3a9a52 = () => interopDefault(import('../pages/components/table.vue' /* webpackChunkName: "pages/components/table" */))
const _755be9a1 = () => interopDefault(import('../pages/components/texteditor.vue' /* webpackChunkName: "pages/components/texteditor" */))
const _61399980 = () => interopDefault(import('../pages/coupons/create.vue' /* webpackChunkName: "pages/coupons/create" */))
const _4bd07996 = () => interopDefault(import('../pages/custom-categories/create.vue' /* webpackChunkName: "pages/custom-categories/create" */))
const _07a3b075 = () => interopDefault(import('../pages/development/horizon.vue' /* webpackChunkName: "pages/development/horizon" */))
const _55a6b8d1 = () => interopDefault(import('../pages/development/log-viewer.vue' /* webpackChunkName: "pages/development/log-viewer" */))
const _81cb49bc = () => interopDefault(import('../pages/development/product-json-exporter.vue' /* webpackChunkName: "pages/development/product-json-exporter" */))
const _7c34b8ca = () => interopDefault(import('../pages/discounts/create.vue' /* webpackChunkName: "pages/discounts/create" */))
const _62dcc00f = () => interopDefault(import('../pages/locations/create.vue' /* webpackChunkName: "pages/locations/create" */))
const _3e7f081e = () => interopDefault(import('../pages/meta-keys/create.vue' /* webpackChunkName: "pages/meta-keys/create" */))
const _6a209c93 = () => interopDefault(import('../pages/newsletters/create.vue' /* webpackChunkName: "pages/newsletters/create" */))
const _00ed711a = () => interopDefault(import('../pages/option-values/create.vue' /* webpackChunkName: "pages/option-values/create" */))
const _1534ab0a = () => interopDefault(import('../pages/orders/drafts.vue' /* webpackChunkName: "pages/orders/drafts" */))
const _7da030e9 = () => interopDefault(import('../pages/pages/create.vue' /* webpackChunkName: "pages/pages/create" */))
const _1686835c = () => interopDefault(import('../pages/product-labels/create.vue' /* webpackChunkName: "pages/product-labels/create" */))
const _8dd8eec2 = () => interopDefault(import('../pages/products/bulk-editor.vue' /* webpackChunkName: "pages/products/bulk-editor" */))
const _36796ab5 = () => interopDefault(import('../pages/products/create.vue' /* webpackChunkName: "pages/products/create" */))
const _3007d494 = () => interopDefault(import('../pages/redirects/create.vue' /* webpackChunkName: "pages/redirects/create" */))
const _88b3049c = () => interopDefault(import('../pages/reviews/create.vue' /* webpackChunkName: "pages/reviews/create" */))
const _57610938 = () => interopDefault(import('../pages/shipping-profiles/create.vue' /* webpackChunkName: "pages/shipping-profiles/create" */))
const _35ea6f05 = () => interopDefault(import('../pages/slugs/create.vue' /* webpackChunkName: "pages/slugs/create" */))
const _15ffadb2 = () => interopDefault(import('../pages/stores/create.vue' /* webpackChunkName: "pages/stores/create" */))
const _da6992c8 = () => interopDefault(import('../pages/tax-rates/create.vue' /* webpackChunkName: "pages/tax-rates/create" */))
const _7226ebe2 = () => interopDefault(import('../pages/themes/create.vue' /* webpackChunkName: "pages/themes/create" */))
const _e05a7fb6 = () => interopDefault(import('../pages/users/create.vue' /* webpackChunkName: "pages/users/create" */))
const _14903e1f = () => interopDefault(import('../pages/wishlists/create.vue' /* webpackChunkName: "pages/wishlists/create" */))
const _64632e86 = () => interopDefault(import('../pages/zones/create.vue' /* webpackChunkName: "pages/zones/create" */))
const _2bbab53e = () => interopDefault(import('../pages/addresses/_id/index.vue' /* webpackChunkName: "pages/addresses/_id/index" */))
const _8dd5e926 = () => interopDefault(import('../pages/blogs/_id/index.vue' /* webpackChunkName: "pages/blogs/_id/index" */))
const _7ae0942f = () => interopDefault(import('../pages/carts/_id/index.vue' /* webpackChunkName: "pages/carts/_id/index" */))
const _d1a39868 = () => interopDefault(import('../pages/categories/_id/index.vue' /* webpackChunkName: "pages/categories/_id/index" */))
const _23793c0f = () => interopDefault(import('../pages/collection-products/_id/index.vue' /* webpackChunkName: "pages/collection-products/_id/index" */))
const _f612a71e = () => interopDefault(import('../pages/collections/_id/index.vue' /* webpackChunkName: "pages/collections/_id/index" */))
const _37013ce9 = () => interopDefault(import('../pages/coupons/_id/index.vue' /* webpackChunkName: "pages/coupons/_id/index" */))
const _874e89d8 = () => interopDefault(import('../pages/custom-categories/_id/index.vue' /* webpackChunkName: "pages/custom-categories/_id/index" */))
const _0b6d38ee = () => interopDefault(import('../pages/discounts/_id/index.vue' /* webpackChunkName: "pages/discounts/_id/index" */))
const _7ff5500c = () => interopDefault(import('../pages/locations/_id/index.vue' /* webpackChunkName: "pages/locations/_id/index" */))
const _aeab0e50 = () => interopDefault(import('../pages/meta-keys/_id/index.vue' /* webpackChunkName: "pages/meta-keys/_id/index" */))
const _321ffcf6 = () => interopDefault(import('../pages/newsletters/_id/index.vue' /* webpackChunkName: "pages/newsletters/_id/index" */))
const _1164e616 = () => interopDefault(import('../pages/option-values/_id/index.vue' /* webpackChunkName: "pages/option-values/_id/index" */))
const _93213c56 = () => interopDefault(import('../pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _3daf00e0 = () => interopDefault(import('../pages/pages/_id/index.vue' /* webpackChunkName: "pages/pages/_id/index" */))
const _5883b58d = () => interopDefault(import('../pages/product-labels/_id/index.vue' /* webpackChunkName: "pages/product-labels/_id/index" */))
const _a1660448 = () => interopDefault(import('../pages/product-variants/_id/index.vue' /* webpackChunkName: "pages/product-variants/_id/index" */))
const _447be394 = () => interopDefault(import('../pages/products/_id/index.vue' /* webpackChunkName: "pages/products/_id/index" */))
const _5975ccf3 = () => interopDefault(import('../pages/redirects/_id/index.vue' /* webpackChunkName: "pages/redirects/_id/index" */))
const _ddb33152 = () => interopDefault(import('../pages/refunds/_id/index.vue' /* webpackChunkName: "pages/refunds/_id/index" */))
const _1cd09377 = () => interopDefault(import('../pages/reviews/_id/index.vue' /* webpackChunkName: "pages/reviews/_id/index" */))
const _53093ef6 = () => interopDefault(import('../pages/shipping-profiles/_id/index.vue' /* webpackChunkName: "pages/shipping-profiles/_id/index" */))
const _4560bd44 = () => interopDefault(import('../pages/slugs/_id/index.vue' /* webpackChunkName: "pages/slugs/_id/index" */))
const _06c837e2 = () => interopDefault(import('../pages/stores/_id/index.vue' /* webpackChunkName: "pages/stores/_id/index" */))
const _d1898b66 = () => interopDefault(import('../pages/tax-rates/_id/index.vue' /* webpackChunkName: "pages/tax-rates/_id/index" */))
const _09694dfa = () => interopDefault(import('../pages/themes/_id/index.vue' /* webpackChunkName: "pages/themes/_id/index" */))
const _314d4fb8 = () => interopDefault(import('../pages/users/_id/index.vue' /* webpackChunkName: "pages/users/_id/index" */))
const _06c05fea = () => interopDefault(import('../pages/wishlists/_id/index.vue' /* webpackChunkName: "pages/wishlists/_id/index" */))
const _3af64223 = () => interopDefault(import('../pages/zones/_id/index.vue' /* webpackChunkName: "pages/zones/_id/index" */))
const _6de2013c = () => interopDefault(import('../pages/products/_id/create-variant.vue' /* webpackChunkName: "pages/products/_id/create-variant" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/addresses",
    component: _5299bbd3,
    name: "addresses"
  }, {
    path: "/blogs",
    component: _1f3c8182,
    name: "blogs"
  }, {
    path: "/carts",
    component: _17c28978,
    name: "carts"
  }, {
    path: "/categories",
    component: _6bbeb161,
    name: "categories"
  }, {
    path: "/collection-products",
    component: _e7f359b8,
    name: "collection-products"
  }, {
    path: "/collections",
    component: _5fff4486,
    name: "collections"
  }, {
    path: "/coupons",
    component: _2dd65e04,
    name: "coupons"
  }, {
    path: "/custom-categories",
    component: _fcbfc6ae,
    name: "custom-categories"
  }, {
    path: "/dashboard",
    component: _866dd17c,
    name: "dashboard"
  }, {
    path: "/dashboard2",
    component: _b3de8e3a,
    name: "dashboard2"
  }, {
    path: "/discounts",
    component: _26019783,
    name: "discounts"
  }, {
    path: "/locations",
    component: _4c3b1c8f,
    name: "locations"
  }, {
    path: "/login",
    component: _500d2497,
    name: "login"
  }, {
    path: "/media",
    component: _26470095,
    name: "media"
  }, {
    path: "/meta-keys",
    component: _ba414726,
    name: "meta-keys"
  }, {
    path: "/newsletters",
    component: _e2f0c0ea,
    name: "newsletters"
  }, {
    path: "/option-values",
    component: _3423ceaa,
    name: "option-values"
  }, {
    path: "/options",
    component: _0bb1294f,
    name: "options"
  }, {
    path: "/orders",
    component: _6a5b682c,
    name: "orders"
  }, {
    path: "/pages",
    component: _5d8daf16,
    name: "pages"
  }, {
    path: "/product-labels",
    component: _e85588bc,
    name: "product-labels"
  }, {
    path: "/product-types",
    component: _5f400c0c,
    name: "product-types"
  }, {
    path: "/products",
    component: _72a8f5ae,
    name: "products"
  }, {
    path: "/redirects",
    component: _dad213f0,
    name: "redirects"
  }, {
    path: "/reviews",
    component: _177cc2e8,
    name: "reviews"
  }, {
    path: "/shipping-profiles",
    component: _36edbacc,
    name: "shipping-profiles"
  }, {
    path: "/slugs",
    component: _a43e924e,
    name: "slugs"
  }, {
    path: "/stores",
    component: _71fa3077,
    name: "stores"
  }, {
    path: "/tags",
    component: _46f92e9e,
    name: "tags"
  }, {
    path: "/tax-rates",
    component: _668d2062,
    name: "tax-rates"
  }, {
    path: "/themes",
    component: _bbebdae2,
    name: "themes"
  }, {
    path: "/users",
    component: _09cc7c8e,
    name: "users"
  }, {
    path: "/vendors",
    component: _295eda9c,
    name: "vendors"
  }, {
    path: "/wishlists",
    component: _53d0c702,
    name: "wishlists"
  }, {
    path: "/zones",
    component: _33818338,
    name: "zones"
  }, {
    path: "/addresses/create",
    component: _2852094b,
    name: "addresses-create"
  }, {
    path: "/blogs/create",
    component: _1ff00d08,
    name: "blogs-create"
  }, {
    path: "/carts/create",
    component: _9126100c,
    name: "carts-create"
  }, {
    path: "/categories/create",
    component: _33cbc57d,
    name: "categories-create"
  }, {
    path: "/collections/create",
    component: _70c4d210,
    name: "collections-create"
  }, {
    path: "/components/apiselect",
    component: _9e1933c2,
    name: "components-apiselect"
  }, {
    path: "/components/autocompleteinput",
    component: _f2eb772a,
    name: "components-autocompleteinput"
  }, {
    path: "/components/breadcrumb",
    component: _603a4c4a,
    name: "components-breadcrumb"
  }, {
    path: "/components/breadcrumblink",
    component: _53984644,
    name: "components-breadcrumblink"
  }, {
    path: "/components/button",
    component: _28c1f90e,
    name: "components-button"
  }, {
    path: "/components/checkbox",
    component: _23bf57ca,
    name: "components-checkbox"
  }, {
    path: "/components/datepicker",
    component: _6556d4fa,
    name: "components-datepicker"
  }, {
    path: "/components/imageeditor",
    component: _361b8bd1,
    name: "components-imageeditor"
  }, {
    path: "/components/imageloader",
    component: _b6de5112,
    name: "components-imageloader"
  }, {
    path: "/components/input",
    component: _a2b11e5a,
    name: "components-input"
  }, {
    path: "/components/modal",
    component: _6b842b94,
    name: "components-modal"
  }, {
    path: "/components/panel",
    component: _1ee713e6,
    name: "components-panel"
  }, {
    path: "/components/popover",
    component: _012079ee,
    name: "components-popover"
  }, {
    path: "/components/priceinput",
    component: _51202d30,
    name: "components-priceinput"
  }, {
    path: "/components/select",
    component: _738c1c43,
    name: "components-select"
  }, {
    path: "/components/table",
    component: _5b3a9a52,
    name: "components-table"
  }, {
    path: "/components/texteditor",
    component: _755be9a1,
    name: "components-texteditor"
  }, {
    path: "/coupons/create",
    component: _61399980,
    name: "coupons-create"
  }, {
    path: "/custom-categories/create",
    component: _4bd07996,
    name: "custom-categories-create"
  }, {
    path: "/development/horizon",
    component: _07a3b075,
    name: "development-horizon"
  }, {
    path: "/development/log-viewer",
    component: _55a6b8d1,
    name: "development-log-viewer"
  }, {
    path: "/development/product-json-exporter",
    component: _81cb49bc,
    name: "development-product-json-exporter"
  }, {
    path: "/discounts/create",
    component: _7c34b8ca,
    name: "discounts-create"
  }, {
    path: "/locations/create",
    component: _62dcc00f,
    name: "locations-create"
  }, {
    path: "/meta-keys/create",
    component: _3e7f081e,
    name: "meta-keys-create"
  }, {
    path: "/newsletters/create",
    component: _6a209c93,
    name: "newsletters-create"
  }, {
    path: "/option-values/create",
    component: _00ed711a,
    name: "option-values-create"
  }, {
    path: "/orders/drafts",
    component: _1534ab0a,
    name: "orders-drafts"
  }, {
    path: "/pages/create",
    component: _7da030e9,
    name: "pages-create"
  }, {
    path: "/product-labels/create",
    component: _1686835c,
    name: "product-labels-create"
  }, {
    path: "/products/bulk-editor",
    component: _8dd8eec2,
    name: "products-bulk-editor"
  }, {
    path: "/products/create",
    component: _36796ab5,
    name: "products-create"
  }, {
    path: "/redirects/create",
    component: _3007d494,
    name: "redirects-create"
  }, {
    path: "/reviews/create",
    component: _88b3049c,
    name: "reviews-create"
  }, {
    path: "/shipping-profiles/create",
    component: _57610938,
    name: "shipping-profiles-create"
  }, {
    path: "/slugs/create",
    component: _35ea6f05,
    name: "slugs-create"
  }, {
    path: "/stores/create",
    component: _15ffadb2,
    name: "stores-create"
  }, {
    path: "/tax-rates/create",
    component: _da6992c8,
    name: "tax-rates-create"
  }, {
    path: "/themes/create",
    component: _7226ebe2,
    name: "themes-create"
  }, {
    path: "/users/create",
    component: _e05a7fb6,
    name: "users-create"
  }, {
    path: "/wishlists/create",
    component: _14903e1f,
    name: "wishlists-create"
  }, {
    path: "/zones/create",
    component: _64632e86,
    name: "zones-create"
  }, {
    path: "/addresses/:id",
    component: _2bbab53e,
    name: "addresses-id"
  }, {
    path: "/blogs/:id",
    component: _8dd5e926,
    name: "blogs-id"
  }, {
    path: "/carts/:id",
    component: _7ae0942f,
    name: "carts-id"
  }, {
    path: "/categories/:id",
    component: _d1a39868,
    name: "categories-id"
  }, {
    path: "/collection-products/:id",
    component: _23793c0f,
    name: "collection-products-id"
  }, {
    path: "/collections/:id",
    component: _f612a71e,
    name: "collections-id"
  }, {
    path: "/coupons/:id",
    component: _37013ce9,
    name: "coupons-id"
  }, {
    path: "/custom-categories/:id",
    component: _874e89d8,
    name: "custom-categories-id"
  }, {
    path: "/discounts/:id",
    component: _0b6d38ee,
    name: "discounts-id"
  }, {
    path: "/locations/:id",
    component: _7ff5500c,
    name: "locations-id"
  }, {
    path: "/meta-keys/:id",
    component: _aeab0e50,
    name: "meta-keys-id"
  }, {
    path: "/newsletters/:id",
    component: _321ffcf6,
    name: "newsletters-id"
  }, {
    path: "/option-values/:id",
    component: _1164e616,
    name: "option-values-id"
  }, {
    path: "/orders/:id",
    component: _93213c56,
    name: "orders-id"
  }, {
    path: "/pages/:id",
    component: _3daf00e0,
    name: "pages-id"
  }, {
    path: "/product-labels/:id",
    component: _5883b58d,
    name: "product-labels-id"
  }, {
    path: "/product-variants/:id",
    component: _a1660448,
    name: "product-variants-id"
  }, {
    path: "/products/:id",
    component: _447be394,
    name: "products-id"
  }, {
    path: "/redirects/:id",
    component: _5975ccf3,
    name: "redirects-id"
  }, {
    path: "/refunds/:id",
    component: _ddb33152,
    name: "refunds-id"
  }, {
    path: "/reviews/:id",
    component: _1cd09377,
    name: "reviews-id"
  }, {
    path: "/shipping-profiles/:id",
    component: _53093ef6,
    name: "shipping-profiles-id"
  }, {
    path: "/slugs/:id",
    component: _4560bd44,
    name: "slugs-id"
  }, {
    path: "/stores/:id",
    component: _06c837e2,
    name: "stores-id"
  }, {
    path: "/tax-rates/:id",
    component: _d1898b66,
    name: "tax-rates-id"
  }, {
    path: "/themes/:id",
    component: _09694dfa,
    name: "themes-id"
  }, {
    path: "/users/:id",
    component: _314d4fb8,
    name: "users-id"
  }, {
    path: "/wishlists/:id",
    component: _06c05fea,
    name: "wishlists-id"
  }, {
    path: "/zones/:id",
    component: _3af64223,
    name: "zones-id"
  }, {
    path: "/products/:id/create-variant",
    component: _6de2013c,
    name: "products-id-create-variant"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
