<template>
  <div class="flex flex-col bg-white p-4 gap-4 rounded-sm">
    <div class="flex items-center w-full gap-4">
      <div class="flex flex-col justify-center">
        <h1 class="text-gray-700 mb-1 text-lg">{{ title }}</h1>
      </div>

      <div class="ml-auto">
        <slot name="right" />
        <slot />
      </div>
    </div>

    <div
      v-if="steps.length > 0"
      class="flex-1 w-full flex items-center justify-start"
    >
      <Breadcrumb :steps="steps" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'

export default {
  components: {
    Breadcrumb
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    steps: {
      type: Array,
      default: () => []
    }
  }
}
</script>
