<template>
  <div v-if="address" v-bind="$attrs">
    <div class="text-gray-700">
      {{ address.name }}
    </div>
    <div class="text-sm text-gray-600">
      {{ address.company_name }}
    </div>
    <div class="text-sm mt-4">
      <div class="text-gray-500">
        {{ address.address_1 }}<br />
        {{ address.address_2 }}
      </div>
      <div class="text-gray-500 mt-2">
        {{ address.province }},<br />
        {{ address.city }},<br />
        {{ address.zip }}
      </div>
      <div
        v-if="address.country && address.country.name"
        class="text-gray-500 mt-2"
      >
        {{ address.country.name }}<br />
      </div>
      <div class="my-2 border-b border-gray-300"></div>
      <div>
        <a
          :href="`tel:${address.phone || phone}`"
          class="flex trans-hover text-gray-700 hover:text-gray-800"
        >
          <Icon name="phone" fixed class="mr-2" />
          {{ cleanPhone(address.phone) || cleanPhone(phone) }}
        </a>
      </div>
      <div class="mt-2">
        <a
          :href="addressToMapLink(address)"
          target="_blank"
          class="flex trans-hover text-gray-700 hover:text-gray-800"
        >
          <Icon name="map-marker" fixed class="mr-2" />
          {{ $t('OrderAddress.view_map') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      default: () => ({})
    },
    phone: {
      type: String,
      default: () => null
    }
  },
  methods: {
    cleanPhone(phone) {
      return phone ? phone.replace(/[|&;$%@"<>(), \-/]/g, '') : ''
    },

    addressToMapLink(address) {
      return `https://www.google.com/maps?q=${encodeURIComponent(
        [
          address.address_1 || '',
          address.address_2 || '',
          address.province || '',
          address.city || '',
          address.zip || '',
          address.country ? address.country.name : ''
        ]
          .filter(Boolean)
          .map((s) => s.replace(/[|&;$%@"<>()+,]/g, ''))
          .join('+')
      )}&z=15`
    }
  }
}
</script>
